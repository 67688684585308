/*-----------------------------------------------------------------------------------*/
/*  VARIABLES
/*-----------------------------------------------------------------------------------*/
const controls = document.querySelector(".controls")
const prev = document.querySelector("#prev");
const next = document.querySelector("#next");
const inputName = document.querySelector("#name");
const form = document.querySelector("#form");
const spansName = document.querySelectorAll(".name");
const btn = document.querySelector("#nextSubmit");
const namePage = document.body.getAttribute("data-name");

//transitions de page
const link = document.querySelectorAll(".btn__anim");
const page = document.querySelector(".transition");

//burger menu
const btnBurger = document.querySelector(".menu__btn");
const menu = document.querySelector(".menu");
const btnNav = document.querySelector(".timeline__btn");
const nav = document.querySelector(".timeline");

//tableau
const tabBtns = document.querySelectorAll(".tab__btn");
const btnTop = document.querySelector(".btn__top");


/*-----------------------------------------------------------------------------------*/
/*  GERER FLECHES CLAVIER
/*-----------------------------------------------------------------------------------*/
if(prev && next){
    //écouter les click des flèche du clavier
    document.addEventListener("keydown", (e) =>{
        if(e.keyCode == 37){
            keyChapter(prev);
            localStorage.page = prev.id;
            transitionKey(prev, "prev")
    
        }else if(e.keyCode == 39){
            keyChapter(next);
            localStorage.page = next.id;
            transitionKey(next, "next")
        }
    });
}


function keyChapter(direction){
    //récupérer mon lien href de mon bouton
    var link = direction.getAttribute("href");
    //ouvrir ce lien
    window.location = link;
}

/*-----------------------------------------------------------------------------------*/
/*  PRENOM
/*-----------------------------------------------------------------------------------*/
if (form){
    //si le champ est rempli -> btn opacity 1
    let timeout;
    inputName.addEventListener("input", (e)=>{
        clearTimeout(timeout);

        //récupérer valeur input
        const value = e.currentTarget.value;

        timeout = setTimeout(() => {
            if (value == 0){
                btn.classList.remove("arrow__input--valid");

            }else{
                btn.classList.add("arrow__input--valid");
            }
        }, 500);
    });

    //Accéder à la page suivante seulement quand le champ est rempli
    btn.addEventListener("click", (e)=>{
        if (inputName.value == 0){
           e.preventDefault();
           inputName.classList.add("error");

        }else{
            getValue();
        }
    });

    //Gerer le btn submit
    form.addEventListener("submit", (e)=>{
        e.preventDefault();
        getValue();
        keyChapter(btn);
    });
}

//Enregistrer le prénom dans en localstorage
function getValue() {
    var name = document.querySelector("#name").value;
    localStorage.name = name;
}

//Si la page contient un span name
//->insérer le prénom enregistré des le localstorage
if (namePage == "namePage"){
    if(!localStorage.getItem("name")) {
        window.location = "chap1.html"

    }else {
        injectName(spansName);
    }
}

//fonction injecter prénom dans le contenu
function injectName(spansName){
    for(let spanName of spansName){
        spanName.innerHTML= localStorage.name;
    } 
}

/*-----------------------------------------------------------------------------------*/
/*  TRANSITIONS DE PAGE
/*-----------------------------------------------------------------------------------*/
if(prev && next){
    //enregister dans le local storage prev ou next
    savePage (prev);
    savePage (next);

    function savePage (direction){
        direction.addEventListener("click", (e)=>{
            localStorage.page = direction.id;
        });
    }

    if(localStorage.page == "next"){
        page.classList.add("transition__next");

    }else if(localStorage.page == "prev"){
        page.classList.add("transition__prev");
    }

    transitionBtn(prev, "prev");
    transitionBtn(next, "next");

    function transitionBtn (pageDirection, pageClass){
        pageDirection.addEventListener("click", function(e){
            
            transitionKey (pageDirection, pageClass);
        
            e.preventDefault();
        });
    }

    function transitionKey (pageDirection, pageClass){
        //récupérer le href, stocker
        const currentLink = pageDirection.getAttribute("href");
        
        //ajouter class .animation au body
        page.classList.add("transition__" + pageClass + "--anim");

        //animation end du body
        page.addEventListener("animationend", function(){

        //active le lien (changement de page)
        window.location = currentLink;
        
        });
    }
}

/*-----------------------------------------------------------------------------------*/
/*  BURGER MENU
/*-----------------------------------------------------------------------------------*/
displayMenu(btnBurger, menu, "menu");
displayMenu(btnNav, nav, "timeline");

function displayMenu(btn, navigation, menuType){
    if(btn){
        btn.addEventListener("click", (e) => {
            navigation.classList.toggle(menuType+"--visible");
            btn.classList.toggle(menuType+"__btn--open");
            document.body.classList.toggle("hidden");
        });
    }
};

/*-----------------------------------------------------------------------------------*/
/*  POP UP
/*-----------------------------------------------------------------------------------*/
popUp("accessoire__btn", "popup");
popUp("enrichir__btn", "enrichir__content");
popUp("maroutine__btn", "maroutine__content");
popUp("geste__btn", "geste__content");

function popUp (btn, content){
    const btnsPlus = document.querySelectorAll("." + btn);

    for (var i = 0; i < btnsPlus.length; i++){
    
        btnsPlus[i].addEventListener ("click", (e) => {
            e.preventDefault();
            let link = e.target.getAttribute ("href");
            let current = document.querySelector(link);
            let contents = document.querySelectorAll("." + content);
    
            if(current.classList.contains(content + "--visible") == true){
                current.classList.remove(content + "--visible");
                e.target.classList.remove(btn + "--visible");
    
              }else{
                if(current.classList.contains("table") == false){
                    for (var i = 0; i < btnsPlus.length; i++) {
                        contents[i].classList.remove(content + "--visible");
                        btnsPlus[i].classList.remove(btn + "--visible");
                    }
                }
                
                current.classList.add(content + "--visible");
                e.target.classList.add(btn + "--visible");
              }
        });
    }
}

/*-----------------------------------------------------------------------------------*/
/*  TABLEAU
/*-----------------------------------------------------------------------------------*/
if(tabBtns){
    tabNavigation(tabBtns);
}

function tabNavigation(tabBtns){

  for(let tabBtn of tabBtns){
    tabBtn.addEventListener("click", (e) => {
      let section = e.currentTarget.getAttribute("data-section");
      tabActiveSection(section);
    });
  }

}

function tabActiveSection(name){
    let openedSection = document.querySelector(".tab__page--open");
    let openedIcon = document.querySelector(".tab__el--actif");

    if(openedSection){
      openedSection.classList.remove("tab__page--open");
    }

    let openSection = document.querySelector(".tab__page--"+name);
    if(openSection){
      openSection.classList.add("tab__page--open");
    }
    
    if(openedIcon){
      openedIcon.classList.remove("tab__el--actif");
    }

    let openIcon = document.querySelector(".tab__el--"+name);
    if(openIcon){
      openIcon.classList.add("tab__el--actif");
    }
}

//BOUTTON TOP
if(btnTop){

    window.addEventListener("scroll", (e)=>{
        btnTop.classList.add("btn__top--visible")

        if(window.scrollY > 100){
            btnTop.classList.add("btn__top--visible");
        }else{
            btnTop.classList.remove("btn__top--visible");
        }
             
    });

    btnTop.addEventListener("click", (e) =>{
        window.scrollTo(0, 0);
    });
}

/*-----------------------------------------------------------------------------------*/
/*  DEFINITIONS - FETCH DONNÉES JSON
/*-----------------------------------------------------------------------------------*/
let url = "scripts/lexique.json";

fetch(url)
    .then(response => response.json() )
    .then(json => {

        let words = document.querySelectorAll(".word");
        let defs = json;

            for(let word of words){
                for(let def of defs){

                    if(word.getAttribute("id") == def.id){

                        //sélectionner l'élément parent de mon mot
                        //lui aujouter la class word__bloc
                        let parentWord = word.parentElement;
                        parentWord.classList.add("word__bloc");

                        //créer div qui contenir ma définition et ajouter la class word__def
                        let myWordDef = document.createElement("div");
                        myWordDef.classList.add("word__def");
                        //insérer ma définition dans le div
                        myWordDef.innerHTML = def.def;
    
                        //ajouter ma définition à l'élément parent du mot
                        parentWord.appendChild(myWordDef);

                        word.addEventListener("click", (e)=>{
                            myWordDef.classList.toggle("word__def--actif");
                        });

                        word.addEventListener("mouseover", (e)=>{
                            myWordDef.classList.add("word__def--actif");
                        });

                        word.addEventListener("mouseout", (e)=>{
                            myWordDef.classList.remove("word__def--actif");
                        });
                    }
                }    
            };
    });

/*-----------------------------------------------------------------------------------*/
/*  POP UP INFOS COMPLEMENTAIRES
/*-----------------------------------------------------------------------------------*/
popUpComp("info-pop__btn");

function popUpComp (btn){
    let btnsRecipe = document.querySelectorAll("." + btn);
    let closeRecipe = document.querySelectorAll(".info-pop__btn--close");

    for (var i = 0; i < btnsRecipe.length; i++){
        
        btnsRecipe[i].addEventListener ("click", function (e) {
            e.preventDefault();
            var link = e.target.getAttribute ("href");
            var current = document.querySelector(link);

            if(current.classList.contains("info-pop--visible") == true){
                current.classList.remove("info-pop--visible");

            }else{
                current.classList.add("info-pop--visible");

                for (var i = 0; i < closeRecipe.length; i++){
                closeRecipe[i].addEventListener("click", (e)=>{
                        current.classList.remove("info-pop--visible");
                    }); 
                }
            }
        });
    }
}